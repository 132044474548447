import CircleIcon from "../miniComponents/circleIcon";
import Timer from "./timer";
import { ReactComponent as AlarmIcon } from '../../assets/icons/mdi_alarm.svg';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { getDay, getDayInMonth, getMonth, CustomPaper, customSxStyles } from "../../pages/timeføring/timeføring";
import { useFetchSingleEditableRS, useSingleFetchEditableRS } from "../utils/hooks/RSFetchSingleEditable";
import { checkIfRCTimeCompleted, completeTime, createDefaultTime, setPrevRcTimes, setRcTime, setRcTimeAsync } from "../../stores/timeSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { checkForTimeOverlap, createId, uploadTimeToDb, uploadTimeToDbRS } from "../../firebase";
import ButtonOnlyHitOnce from "../miniComponents/buttonOnlyHitOnce";
import { SquareRounded } from "@mui/icons-material";

import { ReactComponent as PauseIcon } from '../../assets/icons/mdi_hamburger.svg';
import { ReactComponent as PlayIcon } from '../../assets/icons/ph_play-fill.svg';
import { useTranslation } from "react-i18next";
import useCollectionSubscriptionRS from "../utils/hooks/RSfirebaseCollectionSubscriber";
import { storeEditable } from "../utils/hooks/storeSingleEditable";
import CustomInput from "../miniComponents/customInput";
import CustomerWithProjectAutocomplete from "../autocomplete/CustomerWithProjectAutocomplete";

export function StartAndStopTimer({ flattenedOptions = [] }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);
    const ts = useSelector(state => state.time.prevRcTimes)


    const { rcTimes, isLoading } = useCollectionSubscriptionRS({
        selector: (state) => state.time.prevRcTimes,
        path: `tid/${user.uid}/timer`,
        setFunction: setPrevRcTimes,
        key: "rcTimes",
        payload: {},
        // Only the once created by the user
        filters: [
            { field: 'endDate', operator: '==', value: null }
        ]
    });
    const { time, setTime } = storeEditable({
        selector: state => state.time.rcTime,
        setFunction: setRcTimeAsync,
        key: "time"
    })

    useEffect(() => {
        dispatch(checkIfRCTimeCompleted())
    }, [])

    return (
        <div className="column">
            <div className="column stretch-width time-card">
                <div className="row flexApart">
                    <h2>{getDay(t)}</h2>
                    <h2>{getDayInMonth(new Date(), t)}, {getMonth(new Date(), t)}</h2>
                </div>
                <div className="row flexApart wrap-on-phone center">
                    <div className="row stretch-width">
                        <CircleIcon good={!time?.startDate} recording={time?.startDate} icon={AlarmIcon} />
                        <CustomerWithProjectAutocomplete value={time} onChange={(e) => {
                            setTime(old => ({
                                ...old, project: {
                                    name: e.target.value,
                                }
                            }))
                        }}
                            onChoise={({ project, customer }) => {
                                setTime(old => ({ ...old, project: project, customer: customer }))
                            }
                            }
                        />
                    </div>
                    { /* REMOVED DUE TO SPACE. SHOULD BE ADDED BACK IN A BETTER WAY
                        <ChooseFrom time={time} setTime={setTime} />
                        */}
                </div>

                <div className="row flexApart">
                    <p>{t("Time", "Tid")}:</p>
                    <Timer showSecondsIfNoHour={true} startDate={time?.startDate ? new Date(time?.startDate) : null} countUp={time?.startDate !== null} />
                </div>
            </div>

            <div className="column small-gap">


                <div className="row flexApart stretch-width">
                    <button className={"btn btn-primary" + (time?.pause ? " buttonSelected" : "")} onClick={
                        () => {
                            let timeCopy = { ...time }
                            timeCopy.pause = timeCopy.pause ? false : {
                                hours: 0,
                                minutes: 30,
                            }
                            setTime(timeCopy)
                        }
                    }>{t("Pause", "Pause")} <PauseIcon /></button>

                    {!time?.startDate ?
                        <ButtonOnlyHitOnce key="start-button" className="btn btn-primary good" onClick={
                            async () => {
                                const tempUpdate = {
                                    ...time,
                                    startDate: new Date(),
                                    stoppet: false,
                                }
                                setTime(tempUpdate)
                            }
                        }>{t("Start", "Start")} <PlayIcon /></ButtonOnlyHitOnce>
                        :
                        <button key="stop-button" className="btn btn-primary recording" onClick={
                            () => {
                                let timeCopy = { ...time }
                                timeCopy.endDate = new Date()
                                setTime(old => timeCopy)
                                //const overlap = checkForOverlap(timeCopy)
                            }
                        }>{t("Stop", "Stopp")} <SquareRounded /></button>
                    }
                </div>
            </div>
        </div >
    )
}




const ChooseFrom = ({ time, setTime }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const time_timeString = time?.startDate ? new Date(time?.startDate).toLocaleTimeString() : ""
    const [localState, setLocalState] = useState(time_timeString)

    useEffect(() => {
        setLocalState(time_timeString)
    }, [time])

    if (!time?.startDate) return null
    return (
        <div className="row">
            <CustomInput label={t("Start time", "Starttid")}
                type="time" value={localState}
                onChange={(e) =>
                    setLocalState(old => e.target.value)
                }
                noIcon={true}
                onBlur={async () => {
                    console.log(localState)
                    const tempUpdate = {
                        ...time,
                        startDate: setNewTime(time?.startDate, localState)
                    }
                    if (localState === time_timeString || localState === "") return
                    setTime(tempUpdate)
                }}
            />

        </div>
    )
}

/**
 * 
 * @param {Date || ISOString} date Date Object or ISOString
 * @param {String} time "HH:MM:SS"
 * @returns 
 */
const setNewTime = (date, time) => {
    let newDateTime = new Date(date)
    const [hours, minutes, seconds] = time.split(":")
    newDateTime.setHours(hours)
    newDateTime.setMinutes(minutes)
    newDateTime.setSeconds(seconds || 0)
    return newDateTime
}