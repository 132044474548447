import { createSlice } from "@reduxjs/toolkit";



const TestSlice = createSlice({
    name: 'test',
    initialState: {
        byId: {},
        allIds: [],
    },

    reducers: {
    }
});

export default TestSlice.reducer;