import React, { useState, useRef, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSpring, animated, config } from 'react-spring';
import { useMeasure } from 'react-use';
import { checkRolesForPermission } from '../utils';
import { ArrowDownward, ArrowDropDown } from '@mui/icons-material';
import { useSelector } from 'react-redux';

export default function HeaderTabButton(props) {
    const history = useHistory();
    const location = useLocation();
    const user = useSelector(state => state.user.user);
    const companyUser = useSelector(state => state.user.companyUser);
    const [showChildren, setShowChildren] = useState(false);
    const [ref, { height }] = useMeasure();
    const [hover, setHover] = useState(false);
    const [showChildrenHover, setShowChildrenHover] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowChildrenHover(showChildren);
        }, 400);
    }, [showChildren]);

    const propsSpring = useSpring({
        height: showChildren ? height + 1 : 0,
        opacity: showChildren ? 1 : 0,
        config: { ...config.gentle, friction: 26, tension: 170 },
        from: { height: 0, opacity: 0 },
    });


    function navigateToNewPage(path) {
        history.push(path);
    }

    const [show, setShow] = useState(false);

    useEffect(() => {
        if (props?.permissions !== undefined && !checkRolesForPermission(companyUser?.permissions, props?.permissions)) return setShow(false);
        setShow(true);
    }, [companyUser?.permissions]);

    const [active, setActive] = useState(false);

    useEffect(() => {
        const isActive = location.pathname === props?.navigateTo;
        //console.log('isActive', isActive); // debug log
        setActive(isActive);
    }, [location.pathname, props?.navigateTo]); // Added props?.navigateTo to dependency list

    if (!show || props?.moduleDisabled) return null;

    return (
        <div className='column noGap stretch-width center-column'>
            <button onMouseEnter={() => {
                setHover(true)
            }} onMouseLeave={() => {
                setHover(false)
            }}
                onClick={(e) => {
                    e.preventDefault();
                    setHover(false)
                    if (props?.children) setShowChildren(!showChildren);
                    if (props?.onPress) props?.onPress(e);
                    if (props?.onClick) props?.onClick(e);
                    if (props.navigateTo) navigateToNewPage(props.navigateTo);
                }}
                className={'header-tab-button stretch-width posRelative stretch-width' + (props?.iconOnly ? ' center' : '') + (active ? ' active-icon' : '') + (showChildren ? " open " : "")}
            /*style={{
                borderBottomRightRadius: showChildren ? 0 : 10,
                borderBottomLeftRadius: showChildren ? 0 : 10,
            }}*/
            >
                {props?.icon && <props.icon style={{ width: 24, height: 24 }} className={(active ? ' active-icon' : '')} />}
                {props?.image && <img src={props.image} alt='' />}

                <div className={'row small-gap HeaderButtonDiv stretch-width center-column' + (props?.iconOnly ? "" + (hover ? " headerButtonText" : " headerButtonLeave") : "") + (props.centerText ? ' center' : '')}>
                    {props?.title && <h4 className={active ? 'orange' : ''}
                        style={{
                            fontSize: 12,
                            fontWeight: 700,
                            //textTransform: 'uppercase',
                            margin: 0,
                            //padding: (hover && props?.iconOnly) ? '10px 20px' : 0,
                            width: 'fit-content',
                            lineHeight: '20px'
                        }}>
                        {props.title}
                    </h4>}
                    {props?.suffix}
                    {/*props?.description && <p>{props.description}</p>*/}
                </div>

                {props?.children && <ArrowDropDown style={{
                    width: 24,
                    height: 24,
                    position: 'absolute',
                    right: props?.iconOnly ? 0 : 10,
                    //top: '50%',
                    //transform: 'translateY(-50%)' + ,
                    transition: 'all 0.2s ease-in-out',
                    transform: showChildren ? 'rotate(0deg)' : 'rotate(-90deg)',
                    //opacity: hover ? 1 : 0,
                }} />}

            </button>
            <div className='row noGap stretch-width'>
                <div className='white-margin orangeButton' style={{ width: 5, height: '100%' }}></div>
                <div className='column noGap stretch-width'>
                    {
                        showChildren && <div className='white-margin orangeButton' style={{ width: '100%', height: 1 }}></div>
                    }
                    <animated.div
                        className='column center noGap stretch-width'
                        style={{
                            //marginLeft: 5,
                            marginBlock: showChildrenHover ? 5 : 0,
                            overflowY: showChildrenHover ? 'visible' : 'hidden',
                            transformOrigin: 'top',
                            ...propsSpring,
                        }}
                    >
                        <div ref={ref} className='column center noGap stretch-width' style={{ display: 'block', paddingLeft: props?.iconOnly ? 0 : 20 }}>
                            {showChildren && props?.children && props.children}
                        </div>

                    </animated.div>
                    {
                        showChildren && <div className='white-margin orangeButton' style={{ width: '100%', height: 1, }}></div>
                    }
                </div>

            </div>
        </div>
    );
}
