import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import CustomInput from '../../components/miniComponents/customInput';
import CustomDropdown from '../../components/miniComponents/customDropdown';
import CircleIconButton from '../../components/miniComponents/circleIconButton';
import { Delete } from '@mui/icons-material';
import { CheckIfOnPC } from '../../App';
import TimetypeModalButton from '../../components/modals/timetypeModalButton';





export default function ExtraTime({ time, index, timeToEdit, setTimeToEdit, tillegOptions }) {
    const { t } = useTranslation();
    //console.log(timeToEdit)
    const [timeState, setTimeState] = useState(
        {
            id: time.id,
            from: time.from || decideIfUsePrevious(timeToEdit, index),
            to: decideIfUsePrevious(timeToEdit, index),
            timeType: null,
        }
    )

    useEffect(() => {
        //console.log(timeState)
        setTimeToEdit(prev => ({
            ...prev, extraTimes: prev.extraTimes.map((t, i) => i === index ? {
                ...timeState,
                from: new Date(timeToEdit.startDate).setHours(timeState.from.split(":")[0], timeState.from.split(":")[1]),
                to: new Date(timeToEdit.startDate).setHours(timeState.to.split(":")[0], timeState.to.split(":")[1])
            } : t)
        }))
    }, [timeState])

    return (
        <div className='column' key={time.id}>
            <div className='divider' />
            <div className="row wrap-on-phone small-gap center-column stretch-width" >
                {!CheckIfOnPC() && <div className='divider' />}
                <div className='row small-gap stretch-width'>
                    <CustomInput label={t("From", 'Fra')} type="time" value={timeState.from} onChange={(e) => {
                        setTimeState({ ...timeState, from: e.target.value })
                    }} noIcon={true} />
                    <CustomInput label={t("To", 'Til')} type="time" value={timeState.to} onChange={(e) => {
                        setTimeState({ ...timeState, to: e.target.value })
                    }} noIcon={true} />
                </div>
                <div className='row stretch-width center'>
                    <TimetypeModalButton label={t("Type of Time", "Timetype")} value={time?.timeType}
                        onChange={(e) => {

                            if (e) setTimeToEdit({ ...timeToEdit, extraTimes: timeToEdit.extraTimes.map((t, i) => i === index ? { ...t, timeType: e } : t) })
                        }} />
                    <CircleIconButton icon={Delete} onClick={() => {
                        setTimeToEdit(prev => ({ ...prev, extraTimes: prev.extraTimes.filter((t, i) => i !== index) }))
                    }} />
                </div>
            </div>
        </div>
    )
}

function decideIfUsePrevious(timeToEdit, index) {
    if (timeToEdit?.extraTimes?.length > 1 && timeToEdit?.extraTimes[index - 1]?.to) return new Date(timeToEdit?.extraTimes[index - 1]?.to).toLocaleTimeString().split(" ")[0]
    if (new Date(timeToEdit?.endDate).toLocaleTimeString().split(" ")[0]) return new Date(timeToEdit?.endDate).toLocaleTimeString().split(" ")[0]

    return "00:00"
}