// store/tasksSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { createId } from '../firebase';

export const ProjectVersion = "1.0.1"
//old



export const createDefaultProject = () => {
    return {
        name: "",
        address: "",
        postalCode: "",
        city: "",
        contactPeople: [{ name: "", email: "" }],
        customerConnection: "",
        id: createId(),
        version: ProjectVersion,
        lat: null,
        lng: null,
        access: {
            users: [],
            roles: []
        },
        number: "",

        // Creation date
        creationDate: new Date().toISOString(),

        // Start and end of the project
        startDate: null,
        endDate: null,


        // Timetracking
        normalStart: "00:00",
        normalEnd: "00:00",
    }
}

const projectSlice = createSlice({
    name: 'projects',
    initialState: {
        byId: {},
        allIds: [],
    },
    reducers: {
        addProject: {
            reducer:
                (state, action) => {
                    const { document } = action.payload;
                    //state.byId[path] = subscription;
                    if (state.allIds.includes(document.id)) return;
                    state.allIds.push(document.id);
                    state.byId[document.id] = document;
                },
            prepare: ({ document }) => {

                return {
                    payload: { document: convertToVersion(document) }
                };
            },
        },
        updateProject: {
            reducer: (state, action) => {
                const { document } = action.payload;
                state.byId[document.id] = document;
            },
            prepare: ({ document }) => {

                return {
                    payload: { document: convertToVersion(document) }
                };
            },
        },

        removeProject: (state, action) => {
            //console.log(action.payload);
            const id = action.payload;
            state.allIds = state.allIds.filter(id => id !== id);
            delete state.byId[id];
        },

        resetProjects: (state) => {
            state.allIds = [];
            state.byId = {};
        }
    },
});


export const { addProject, updateProject, removeProject, resetProjects } = projectSlice.actions

export default projectSlice.reducer;

/*const convertToVersion = (project) => {
    return project;
}*/

const convertVersionToNumber = (version) => {
    if (!version) return 0;
    const versions = version.split('.');
    return Number(versions.join(''));
}

const convertToVersion = (project) => {
    if (project?.version === ProjectVersion) return project;
    if (convertVersionToNumber(project?.version) > convertVersionToNumber(ProjectVersion)) {
        // TODO - Maybe add major version differences, so sometimes it doesnt matter.
        // like 1.0.1 to 1.0.2 is not a big deal, but 1.0.7 to 1.1.0 is a big deal.
        ErrorHandler({
            error: new Error("Your project is newer than the current version of the app. Please update the app."),
            message: "Your project is newer than the current version of the app. Please update the app.",
            actions: [{
                name: "Update", functionToExecute: () => {
                    // TODO - Add a link to the app store.
                }
            }
            ]
        })
        return project;
    }
    let projectTConvert = { ...project };
    if (!projectTConvert.version) projectTConvert = convertToVersion1_0_1(projectTConvert);
    return projectTConvert;
}

const convertToVersion1_0_1 = (project) => {
    console.log(Array.isArray(project.kontaktpersoner));
    return {
        ...project, // For now, should be strict later.
        name: project.navn,
        address: project.addresse,
        postalCode: project.postnummer,
        city: project.by,
        contactPeople: Array.isArray(project.kontaktpersoner) ? project?.kontaktpersoner?.map(person => ({ name: person.navn || "", email: person.email || "", id:createId() })) : [],
        id: project.id,
        version: "1.0.1",
        lat: project?.lat || null,
        lng: project?.lng || null,
        customerConnection: project?.firmaConnection,
        id: project?.id,
        version: ProjectVersion,
        access: {
            users: project?.access?.users || [],
            roles: project?.access?.roles || []
        },
        number: project?.nummer || "",

        // Creation date
        creationDate: new Date().toISOString(),

        // Start and end of the project
        startDate: null,
        endDate: null,


        // Timetracking
        normalStart: project?.klokkeStart || "00:00",
        normalEnd: project?.klokkeSlutt || "00:00",
    };
}
