import { createSlice } from "@reduxjs/toolkit";



const CompanySettingsSlice = createSlice({
    name: 'companySettings',
    initialState: {
        byId: {},
    },

    reducers: {
        setSetting: (state, action) => {
            const key = action.payload.key;
            const docs = action.payload.documents;
            docs.forEach(doc => {
                //console.log("DOC: ", doc);
                state.byId[key] = doc;
            });
            //state.byId[action.payload.key] = action.payload.value;
        }
    }
});

export const { setSetting } = CompanySettingsSlice.actions

export default CompanySettingsSlice.reducer;