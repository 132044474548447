import { useTranslation } from "react-i18next";
import { CustomPaper, customSxStyles } from "./styles";
import { CollSub } from "../utils/hooks/sub/fetchCollection";
import { setSetting } from "../../stores/companySettingsSlice";
import { useSelector } from "react-redux";
import { Autocomplete, TextField } from "@mui/material";
import { arrayIncludesArray } from "../utils/hooks/filterUtils";





export default function ActivityAutocomplete({ value, onChange, onChoise }) {
    const { t } = useTranslation();
    const companySettings = useSelector((state) => state.companySettings);
    const companyUser = useSelector((state) => state.user.companyUser);
    console.log(companyUser)
    //console.log(companySettings)
    const { settings, isLoading } = CollSub({
        selector: (state) => state.companySettings.byId.timeSettings,
        path: 'settings',
        setFunction: setSetting,
        key: "settings",
        payload: { key: "timeSettings" },
        //returnItemsByIdSelector: (state) => state.customers.byId,
        // Only the once created by the user
        filters: [
        ]
    });

    if (isLoading || !settings?.aktiviteter) return <p>Loading...</p>


    const flattenedAktiviteter = settings?.aktiviteter?.reduce((acc, aktivitet, aktivitetIndex) => {
        const keepActivity = aktivitet.roles.some(role => companyUser?.roleIds?.includes(role.id));
        //console.log(keepActivity, aktivitet.roles, companyUser.roleIds)
        if (!keepActivity) return acc;
        aktivitet.underAktiviteter.forEach((underAktivitet, underAktivitetIndex) => {
            // Generate a unique ID. This uses aktivitet's id and indexes as fallbacks
            const uniqueId = underAktivitet.id ?
                `${aktivitet.id}-${underAktivitet.id}` :
                `aktivitet-${aktivitetIndex}-underAktivitet-${underAktivitetIndex}`;

            // Add underAktivitet to the accumulator with additional properties
            acc.push({
                ...underAktivitet,
                aktivitetName: aktivitet.navn,
                aktivitetId: aktivitet.id,
                uniqueId: uniqueId // Use the generated unique identifier
            });
        });
        return acc;
    }, []);

    const idToNameMapping = settings?.aktiviteter?.reduce((acc, aktivitet) => {
        acc[aktivitet.id] = aktivitet.navn;
        return acc;
    }, {});

    return (
        <Autocomplete
            id="aktivitet"
            options={flattenedAktiviteter}
            PaperComponent={CustomPaper}
            groupBy={(option) => option.aktivitetId}
            getOptionLabel={(option) => option.navn}
            style={{ backgroundColor: "var(--input-background)", borderRadius: 5, }}
            sx={customSxStyles}
            renderInput={(params) => <TextField {...params} label={t("Activity", "Aktivitet")} />}
            renderGroup={(params, index) => {
                const groupName = idToNameMapping[params.group];
                return (
                    <div key={`${params.group}-${index}`} style={{ background: "var(--input-background)" }}>
                        <p className="orange" style={{ marginLeft: 10 }}>{groupName}</p>
                        <p className="stretch-width" style={{ background: "var(--input-background)", padding: "10px 5px", listStyle: 'none', margin: 0 }}>
                            {params.children}
                        </p>
                    </div>
                )
            }}

            filterOptions={(options, { inputValue }) => {
                try {
                    let results = [];
                    let lowerCaseInputValue = inputValue.toLowerCase(); // Convert input value to lowercase for case-insensitive comparison

                    options.forEach(option => {
                        // Convert clientName and navn to lowercase and check if they include the lowercase input value
                        if (option && option.aktivitetName && option.aktivitetName.toLowerCase().includes(lowerCaseInputValue)) {
                            results.push(option);
                        }
                        else if (option && option.navn && option.navn.toLowerCase().includes(lowerCaseInputValue)) {
                            results.push(option);
                        }
                    });

                    return results;
                } catch (error) {
                    console.error("Error filtering options:", error);
                    return []; // Return an empty array in case of error
                }
            }}

            isOptionEqualToValue={(option, value) => option.id === value.id}

            fullWidth={true}
            onChange={(e, value) => {
                console.log(value)
                if (onChange) onChange(e, value);
                //if (!value) return setTimeToEdit((old) => { return { ...old, aktivitet: null, underAktivitet: null } })
                //setTimeToEdit((old) => { return { ...old, activity: { activity: value.aktivitetId, value } } })
            }}
            value={value}
        />
    )
}
