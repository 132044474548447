




// authSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { firebaseLogout } from '../firebase';
import { resetCompany } from './companySlice';

const initialState = {
  user: null,
  companyUser: null,
  isAuthenticated: false,
};

export const logoutUser = createAsyncThunk('user/logout', async (_, { dispatch }) => {
  console.log("Got executed")
  //console.log("Logged out in thunk")
  dispatch(resetCompany());
  console.log("Reset Companies")
  await firebaseLogout(); // Calls the synchronous logout action
  console.log("Logged out in firebase")
  dispatch(logout());
  console.log("Logged out in thunk")
});

const authSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    companyUser: null,
    isAuthenticated: false,
  },
  reducers: {
    loginSuccess: (state, action) => {
      console.log("Login success in slice", action.payload)
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.user = initialState.user;
      state.companyUser = initialState.companyUser;
      state.isAuthenticated = initialState.isAuthenticated;
      console.log("Logged out in slice", state, initialState)
    },

    setCompanyUser: (state, action) => {
      state.companyUser = action.payload;
    },
  },
});

export const { loginSuccess, logout, setCompanyUser } = authSlice.actions;
export default authSlice.reducer;
