
// Create the context
import React, { useEffect, useState } from "react";
import { getCompanyDetails, getFirmSettings, getThisCompany } from "../../database/company";

import { useDispatch, useSelector } from "react-redux";
import { setCId, setCompany, setCompanyDetails, setModuleSettings } from "../companySlice";
import store from "../mainStore";
import { setCompanyUser } from "../authSlice";
import { getCompanyUser } from "../../database/users";


// Create the provider component
// TODO: Replace custom event with dispatch
// TODO: MAKE INTO INDIVIDUAL FUNCTIONS, PROMISES DONT RETURN QUERY SNAPSHOTS
export const CompanyObserver = ({ children }) => {
    const dispatch = useDispatch();
    const companyId = useSelector(state => state.company.companyId);
    const company = useSelector(state => state.company);
    const user = useSelector(state => state.user.user);

    useEffect(() => {
        // Listen for companyId changes - should be replaced with dispatch later.
        const updateCompanyIdState = (e) => {
            console.log(e)
            dispatch(setCompanyId(e.detail));
        };
        window.addEventListener("companyIdChange", updateCompanyIdState);
        /*window.addEventListener("storage", (event) => {
            if (event.key === "companyId") {
                setCompanyId(event.newValue);
            }
        });*/
        
        return () => {
            window.removeEventListener("companyIdChange", updateCompanyIdState);
        };
    }, []);

    const fetchCompany = async () => {
        /*const onUpdate = (e) => {
            dispatch(setCompany(e));
        }*/
        const company = await getThisCompany(false);
        dispatch(setCompany(company));
    };

    const getSettings = async () => {
        /*const onUpdate = (e) => {
            dispatch(setModuleSettings(e));
        }*/
        const settings = await getFirmSettings("moduleSettings", false);
        dispatch(setModuleSettings(settings));
    }

    async function getCompanyDT() {
        if (!companyId) return console.log("No company id");
        console.log("getting company details")
        const deails = await getCompanyDetails();
        dispatch(setCompanyDetails(deails));
    }

    const getNewCUser = async () => {
        console.log("getting company user")
        if (!companyId) return console.log("No company id");
        const cUser = await getCompanyUser(user);
        console.log(cUser);
        dispatch(setCompanyUser(cUser));
        /*setUserContext(old => {
            return { ...old, companyUser: cUser }
        });*/
    }

    useEffect(() => {
        if (!companyId) return;
        if (!user) return;
        Promise.all([fetchCompany(), getSettings(), getCompanyDT(), getNewCUser()]);
    }, [companyId, user]);
};


export const setCompanyId = (newCompanyId) => {
    store.dispatch(setCId(newCompanyId));
};
