

export function searchObjects(objectArray, searchString, searchTerms, keysToSearch=[]) {
    let results = [];

    function checkValue(value) {
        // Return an empty string if the value is null or undefined,
        // otherwise return the string representation of the value.
        return (value === null || value === undefined) ? "" : value.toString();
    }
    
    if (!objectArray) return [];

    for (let i = 0; i < objectArray?.length; i++) {
        let object = {...objectArray[i]};
        let score = 0;
        let matchesSearchTerms = true;

        // Check if object matches search terms
        if (searchTerms) {
            for (let searchTerm in searchTerms) {
                if (object[searchTerm] !== searchTerms[searchTerm]) {
                    matchesSearchTerms = false;
                    break;
                }
            }
        }

        // If it matches, add a bonus score
        /*if (matchesSearchTerms && searchTerms) {
            score += 5;
        }*/

        for (const [key, value] of Object.entries(object)) {
            if (keysToSearch?.length && !keysToSearch.includes(key)) {
                continue;
            }

            const stringValue = checkValue(value);  // Ensure value is a string
            const regEx = new RegExp(searchString, 'ig');
            const match = stringValue.match(regEx);
            if (match) {
                score += Number(match?.length * searchString?.length);  // Increment score based on number of letters matched
            }
        }

        object.score = score;
        results.push(object);
    }

    console.log(results);  // Debugging purpose
    return sortObjects(results);
}








export function sortObjects(objectArray) {
    return objectArray.sort((a, b) => b.score - a.score);  // Sort the array of objects in descending order based on their score property.
}

function checkValue(value) {
    let checkValue = value;  // Initialize a variable to store the checked value.

    if (value instanceof Date) {  // If the value is a Date object, format it as a string in the format "dd/mm/yyyy".
        checkValue = `${value.getDate()}/${value.getMonth() + 1}/${value.getFullYear()}`;
    }

    return checkValue;  // Return the checked value.
}



/* Example */
/*
const objA = { name: 'John', age: 25, city: 'New York' };
const objB = { name: 'Jane', age: 30, city: 'London' };
const objC = { name: 'Bob', age: 35, city: 'Paris' };
const objD = { name: 'Bob2', age: new Date, city: '20' };

const objectArray = [objA, objB, objC, objD];

const searchString = '20';

const results = searchObjects(objectArray, searchString);
console.log(results);
*/
