// store/tasksSlice.js
import { createSlice } from '@reduxjs/toolkit';


const subscriptionSlice = createSlice({
    name: 'subscriptions',
    initialState: {
        byId: {},
        allIds: [],
    },
    reducers: {
        addSubscription: {
            reducer:
                (state, action) => {
                    const { path } = action.payload;
                    state.byId[path] = action.payload;
                    console.log(path);
                    console.log("SUBSCRIPTION ADDED: ", path);
                    if (state.allIds.includes(path)) return;
                    state.allIds.push(path);
                },
            prepare: ({ path, subscription }) => {
                return {
                    payload: { path, subscription }
                };
            },
        },
        updateSubscription: {
            reducer: (state, action) => {
                const { path, ...props } = action.payload;
                state.byId[path] = {...state.byId[path], ...props};
            },
        },
        removeSubscription: (state, action) => {
            //console.log(action.payload);
            const path = action.payload;
            state.allIds = state.allIds.filter(id => id !== path);
        },

        resetSubscriptions: (state) => {
            state.allIds = [];
            state.byId = {};
        }
    },
});


export const { addSubscription, removeSubscription, updateSubscription, resetSubscriptions } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
