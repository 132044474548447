import React, { useContext, useEffect, useRef, useState } from "react";
import { BrukerVilkår, parseInput } from "../pages/login/createCompany";
import { createCompany, getCompanyId, getThisCompany, updateCompany } from "../firebase";
import { useHistory, useLocation } from "react-router";
import TilbakeKnapp from "./miniComponents/tilbakeKnapp";
import Canvas from "./canvas";
import { useTranslation } from "react-i18next";
import CustomEditInput from "./miniComponents/customEditInput";
import ButtonOnlyHitOnce from "./miniComponents/buttonOnlyHitOnce";
import { useDispatch, useSelector } from "react-redux";
import { resetCompany } from "../stores/companySlice";

export default function SigningWarning() {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const user = useSelector(state => state.user.user);
    const companyUser = useSelector(state => state.user.companyUser);
    const dispatch = useDispatch();
    //const [DBCompany, setDBCompany] = useState(null);
    const dbCompany = useSelector(state => state.company.company);
    const [company, setCompany] = useState(dbCompany);
    //const company = useSelector(state => state.company.company);
    //console.log(company)
    const [startedSignering, setStartedSignering] = useState(false);
    const [reRender, setReRender] = useState(false);
    const [shouldBeRendered, setShouldBeRendered] = useState(0);

    const divRef = useRef(null);

    useEffect(() => {
        if (company?.signed) return;
        if (!company) return;

        if (divRef.current && shouldBeRendered === 0) setShouldBeRendered(1);
        if (shouldBeRendered === 0 && !divRef.current) return;

        const targetNode = divRef.current;

        const callback = (mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    if (!document.body.contains(targetNode)) {
                        if (shouldShow()) return;
                        console.log('Div has been removed!');
                        window.location.reload();
                    }
                }
            }
        };

        const observer = new MutationObserver(callback);

        const config = { childList: true, subtree: true };

        observer.observe(document.body, config);

        return () => {
            observer.disconnect();
        };
    }, [divRef, company]);

    useEffect(() => {
        if (reRender) {
            setReRender(false);
        }
    }, [reRender]);

    /*useEffect(() => {
        const fetchCompany = async () => {
            if (!getCompanyId()) return;
            const onUpdate = (querySnapshot) => {
                setDBCompany(querySnapshot);
            };

            const newCompany = await getThisCompany(onUpdate);
            setCompany(newCompany);
        };
        fetchCompany();
    }, []);*/

    useEffect(() => {
        if (!company) return;
        if (company?.signed) return;
        if (!company?.couponCode) return;
        const setSalesCode = async () => {
            const companyParse = await parseInput(company?.couponCode);
            setCompany(old => ({ ...old, ...companyParse }));
        };
        setSalesCode();
    }, [dbCompany?.couponCode]);

    const shouldShow = () => {
        return false; // CURRENTLY DISABLED DUE TO ISSUES IN @CompanyObserver
        if (!company) return false;
        const path = window.location.pathname;
        const forbiddenPaths = ["/tab3", "/chooseCompany", "/company", "/login", "/register", "/createCompany", "glemt-passord", "/reset-password", "/resetPassword"];
        if (forbiddenPaths.includes(path)) return false;
        if (!companyUser?.permissions?.includes("admin") && dbCompany?.signed) return false;
        if (dbCompany?.signed) return false;
        if (reRender) return false;

        return true;
    };

    const onSubmit = async (e) => {
        console.log("Submit");
        e.preventDefault();
        await updateCompany({
            ...company,
            signed: true,
            userAccepted: true,
            userSigned: user?.uid
        });
    }

    if (!shouldShow()) return null;
    return (
        <div
            className="signingWarning padding column center-column"
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 600,
                backdropFilter: "blur(10px)",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                overflowX: "hidden",
                overflowY: "scroll",
            }}
        >
            <div className="stretch-width" style={{ maxWidth: 800 }}>
                <TilbakeKnapp
                    onClick={() => {
                        dispatch(resetCompany());
                    }}
                />
            </div>
            <form
                className="padding column center-column"
                onSubmit={onSubmit}
                ref={divRef}

            >
                <div className="column" style={{ maxWidth: "1000px" }}>
                    <h1>{t("Welcome to EffektivJobb", "Velkommen til EffektivJobb")}</h1>
                    <h3>{t("You have to sign the user agreement before you can use the company", "Du må signere brukervilkårene før du kan bruke dette firmaet")}</h3>
                    <div className="division-line orange"></div>
                    <div className="info row column-on-phone center-row">
                        <div className="column small-gap">
                            <h1>{t("Company Information", "Firma Informasjon")}</h1>
                            <CustomEditInput
                                required
                                label={t("Company Name", "Firma Navn")}
                                onChange={(e) => {
                                    setCompany({
                                        ...company,
                                        name: e.target.value
                                    });
                                }} value={company?.name}
                            />
                            <CustomEditInput
                                required
                                label={t("Organization Number", "Organisasjonsnummer")}
                                type="number"
                                onChange={(e) => {
                                    setCompany({
                                        ...company,
                                        orgNr: e.target.value
                                    });
                                }} value={company?.orgNr}
                            />
                            <CustomEditInput
                                required
                                label={t("Company Address", "Firma Adresse")}
                                onChange={(e) => {
                                    setCompany({
                                        ...company,
                                        address: e.target.value
                                    });
                                }} value={company?.address}
                            />
                            <CustomEditInput
                                required
                                label={t("Postal Code", "Postnummer")}
                                onChange={(e) => {
                                    setCompany({
                                        ...company,
                                        postalCode: e.target.value
                                    });
                                }} value={company?.postalCode}
                            />
                            <CustomEditInput
                                required
                                label={t("City", "By")}
                                onChange={(e) => {
                                    setCompany({
                                        ...company,
                                        city: e.target.value
                                    });
                                }} value={company?.city}
                            />
                        </div>
                        <div className="division-line-vertical orange"></div>
                        <div className="column small-gap">
                            <h1>{t("Company Contact Information", "Firma Kontakt Informasjon")}</h1>
                            <h3>{company?.leader}</h3>
                            <CustomEditInput
                                required
                                label={t("Phone Number", "Telefonnummer")}
                                onChange={(e) => {
                                    setCompany({
                                        ...company,
                                        number: e.target.value
                                    });
                                }} value={company?.number}
                            />
                            <CustomEditInput
                                required
                                label={t("Email", "Epost")}
                                onChange={(e) => {
                                    setCompany({
                                        ...company,
                                        email: e.target.value
                                    });
                                }} value={company?.email}
                            />
                        </div>
                        <div className="division-line-vertical orange"></div>
                        <div className="column small-gap">
                            <h1>{t("Payment Information", "Betalings Informasjon")}</h1>
                            <h3>{t("Payment Method:", "Betalingsmetode:")} {company.paymentMethod} Faktura</h3>
                            {company?.invoiceMail && (
                                <CustomEditInput
                                    required
                                    label={t("Invoice Email", "Faktura Epost")}
                                    onChange={(e) => {
                                        setCompany({
                                            ...company,
                                            invoiceMail: e.target.value
                                        });
                                    }} value={company?.invoiceMail}
                                />
                            )}
                        </div>
                    </div>
                    <div className="division-line orange"></div>
                    <h1>{t("User Agreement", "Brukervilkår")}</h1>
                    <BrukerVilkår data={company} />
                    <div className="flexApart">
                        <div />
                        <ButtonOnlyHitOnce type="submit" className="ny" onClick={() => {
                            console.log(divRef.current.checkValidity())
                            if (!divRef.current.checkValidity()) {
                                console.log("Form is invalid. Please fill out all required fields.");
                                divRef.current.reportValidity(); // Show native browser validation errors
                                return;
                            }

                        }}>
                            {t("Understood", "Jeg forstår brukervilkårene")}
                        </ButtonOnlyHitOnce>
                    </div>
                </div>
            </form>
        </div>
    );
}
