import { createSlice } from "@reduxjs/toolkit";



const EditSlice = createSlice({
    name: 'edits',
    initialState: {
        byId: {},
        //allIds: [],
    },

    reducers: {
        setEditState: {
            reducer: (state, action) => {
                const { id, data } = action.payload;
                state.byId[id] = data;
            },
        },
        removeEditState: {
            reducer: (state, action) => {
                const { id } = action.payload;
                delete state.byId[id];
            },
        }
    }
});

export default EditSlice.reducer;
export const { setEditState, removeEditState } = EditSlice.actions;