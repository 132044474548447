import { createSlice } from "@reduxjs/toolkit";
import { getMonthId, getWeekId } from "../pages/timeføring/utils/utils";
import { addToMonth, addToWeek, cleanUpWeekAndMonth, removeFromWeek } from "./timeSlice";

const PlannedTimesSlice = createSlice({
    name: 'plannedTimes',
    initialState: {
        allTimes: {
            byId: {},
            allIds: []
        },
        months: {
            byId: {},
            allIds: []
        },
        weeks: {
            byId: {},
            allIds: []
        },
    },

    reducers: {
        setPlannedTimes: {
            reducer: (state, action) => {
                const { documents } = action.payload;

                documents.forEach(document => {
                    const preparedDocument = document
                    const weekId = getWeekId(new Date(document.startDate));
                    const monthId = getMonthId(new Date(document.startDate));
                    const date = new Date(document.startDate);

                    // Add the time to allTimes
                    state.allTimes.byId[document.id] = preparedDocument;
                    if (!state.allTimes.allIds.includes(document.id)) {
                        state.allTimes.allIds.push(document.id);
                    }

                    // Add the time to the respective week and month
                    addToWeek(state, weekId, document, date);
                    addToMonth(state, monthId, weekId);
                });
            },
            prepare: ({ documents }) => {
                return {
                    payload: {
                        documents: documents
                    }
                };
            }
        },

        // Add a new time
        addPlannedTimes: {
            reducer: (state, action) => {
                //console.log("addPlannedTimes", action.payload);
                const { document } = action.payload;
                console.log("addPlannedTimes", document)
                const weekId = getWeekId(new Date(document.startDate));
                const monthId = getMonthId(new Date(document.startDate));
                const date = new Date(document.startDate);
                // Add the new time to allTimes

                state.allTimes.byId[document.id] = document;
                if (!state.allTimes.allIds.includes(document.id)) state.allTimes.allIds.push(document.id);

                // Add the time to the respective week and month
                addToWeek(state, weekId, document, date);
                addToMonth(state, monthId, weekId);
            },
            prepare: ({ document }) => {
                return {
                    payload: {
                        document: document
                    }
                };
            }
        },

        // Update an existing time
        updatePlannedTimes: {
            reducer: (state, action) => {
                const { document } = action.payload;
                const weekId = getWeekId(new Date(document.startDate));
                const monthId = getMonthId(new Date(document.startDate));
                const date = new Date(document.startDate);

                console.log("updatePlannedTimes", document)
                // Update the time in allTimes
                state.allTimes.byId[document.id] = document;

                // Ensure the time is correctly associated with the week and month
                addToWeek(state, weekId, document, date);
                addToMonth(state, monthId, weekId);
            },
            prepare: ({ document }) => {
                return {
                    payload: {
                        document: document
                    }
                };
            }
        },

        // Remove a time
        removePlannedTimes: {
            reducer: (state, action) => {
                const { document } = action.payload;
                const weekId = getWeekId(new Date(document.startDate));
                const monthId = getMonthId(new Date(document.startDate));

                // Remove from allTimes
                delete state.allTimes.byId[document.id];
                state.allTimes.allIds = state.allTimes.allIds.filter(id => id !== document.id);

                // Remove from the week
                removeFromWeek(state, weekId, document.id);

                // Optionally, clean up the week and month if they're empty
                cleanUpWeekAndMonth(state, weekId, monthId);
            },
            prepare: ({ document }) => {
                return {
                    payload: {
                        document: document
                    }
                };
            }
        },

        resetPlannedTimes: (state) => {
            state.allTimes.byId = {};
            state.allTimes.allIds = [];
            state.months.byId = {};
            state.months.allIds = [];
            state.weeks.byId = {};
            state.weeks.allIds = [];
        }
    }
});

export const { setPlannedTimes, addPlannedTimes, updatePlannedTimes, removePlannedTimes, resetPlannedTimes } = PlannedTimesSlice.actions;
export default PlannedTimesSlice.reducer;
