import { CheckRounded, Delete, Edit, Mail, More, MoreHoriz, PlayArrow, Settings, SquareRounded } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { calculateTimePassed } from "./timeCards";
import CustomerWithProjectAutocomplete from "../autocomplete/CustomerWithProjectAutocomplete";
import { useEffect, useRef, useState } from "react";
import { getDay, getDayInMonth, getMonth } from "../../pages/timeføring/timeføring";
import { Menu, MenuItem, Tooltip } from "@mui/material";
import { CountdownTimerWithControls } from "./pcStartAndStopTimer";
import ButtonOnlyHitOnce from "../miniComponents/buttonOnlyHitOnce";
import { ReactComponent as PauseIcon } from '../../assets/icons/mdi_hamburger.svg';
import { useDispatch, useSelector } from "react-redux";
import { setRcTimeAsync, updateTimeAsync } from "../../stores/timeSlice";
import { useHistory } from "react-router";
import { deleteTimeFromDB } from "../../firebase";

export function PcSimplifiedTimeCard({ time, onClick, onDelete, onEdit }) {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const rcTime = useSelector(state => state.time.prevRcTimes)
    const user = useSelector(state => state.user.user)
    const setTime = (time) => {
        dispatch(updateTimeAsync(time))
    }
    const [menuAnchor, setMenuAnchor] = useState(false)
    const anchorButton = useRef(null)

    const onView = () => {
        //onEdit()
        history.push(`/timeføring/${time.id}`, {
            timeToEdit: time
        })
        setMenuAnchor(false)
    }

    const onDeleteF = async () => {
        await deleteTimeFromDB(time, user)
        setMenuAnchor(false)
    }

    return (
        <div className="row stretch-width center-column">
            <div className="column noGap stretch-width">
                <div className="row small-gap time-top">
                    <h2 className="small-text">{getDay(t)}, {getDayInMonth(new Date(), t)}. {getMonth(new Date(), t)}</h2>
                </div>
                <div className="row stretch-width center-column time-bottom wrap-on-phone center">
                    <div className="row stretch-width center-column">
                        <div className="row flexApart wrap-on-phone stretch-width">
                            <div className="row stretch-width">
                                <CustomerWithProjectAutocomplete value={time} onChange={(e) => {
                                    setTime(old => ({
                                        ...old, project: {
                                            name: e.target.value,
                                        }
                                    }))
                                }}
                                    onChoise={({ project, customer }) => {
                                        setTime(old => ({ ...old, project: project, customer: customer }))
                                    }
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row small-gap">
                        <div className="row">
                            <Tooltip title={t("Pause", "Pause")} placement="top">
                                <button className={"btn btn-primary icon-only-button stretch-height one-to-one" + (time?.pause ? " active" : "")} onClick={
                                    () => {
                                        let timeCopy = { ...time }
                                        timeCopy.pause = timeCopy.pause ? false : {
                                            hours: 0,
                                            minutes: 30,
                                        }
                                        setTime(timeCopy)
                                    }
                                }> <PauseIcon /></button>
                            </Tooltip>

                            <CountdownTimerWithControls time={time} setTime={setTime} count={false} />

                            <Tooltip title={t("Start", "Start")} placement="top">
                                <button key="start-button" className="icon-only-button stretch-height one-to-one" onClick={
                                    async () => {
                                        dispatch(setRcTimeAsync({
                                            ...rcTime,
                                            startDate: rcTime?.startDate ? rcTime.startDate : new Date(),
                                            project: time?.project || null,
                                            customer: time?.customer || null,

                                        }))
                                    }
                                }><PlayArrow /></button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div >
            <Tooltip title={t("More", "Mer")} placement="top">
                <button ref={anchorButton} className="icon-only-button one-to-one" onClick={() => {
                    setMenuAnchor(true)
                }}><MoreHoriz /></button>
            </Tooltip>

            <Menu
                anchorEl={anchorButton.current}
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(null)}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem className="row" onClick={onView}>{t("Edit", "Rediger")}<Edit /></MenuItem>
                <MenuItem className="row" onClick={onDeleteF}>{t("Delete", "Slett")}<Delete/></MenuItem>
            </Menu>
        </div>
    )
}