import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // uses localStorage by default
import AuthSlice from './authSlice';
import companySlice from './companySlice';
import subscriptionsSlice from './subscriptionsSlice';
import hmsSlice from './hmsSlice';
import timeSlice from './timeSlice';
import { timestampMiddleware } from './utils/timestampMiddleware';
import { thunk } from 'redux-thunk';
import customerSlice from './customerSlice';
import projectsSlice from './projectsSlice';
import rehydrateRcTimeMiddleware from './utils/rcTimeMiddleware';
import plannedTimeSlice from './plannedTimeSlice';
import usersSlice from './usersSlice';
import { nameMiddleware } from './utils/name-middleware';
import testSlice from './test-slice';
import reportsSlice from './reportsSlice';
import companySettingsSlice from './companySettingsSlice';
import editSlice from './edit-slice';

// Configure persistence for the auth slice only
const persistConfig = {
    key: 'auth', // Persist only the auth slice
    storage,
    whitelist: ['user', 'isAuthenticated'], // Define the state keys to persist
};

const companyPersistConfig = {
    key: 'company', 
    storage,
    whitelist: ['company', 'companyDetails', "companyId"], // Define the state keys to persist
};

const timePersistConfig = {
    key: 'time', 
    storage,
    whitelist: ["rcTime"], // Define the state keys to persist
};

// Persist the auth slice using the persistConfig
const persistedAuthReducer = persistReducer(persistConfig, AuthSlice);
const persistedCompanyReducer = persistReducer(companyPersistConfig, companySlice);
const persistedTimeReducer = persistReducer(timePersistConfig, timeSlice);
// Configure store with middleware adjustments for non-serializable values
const store = configureStore({
    reducer: {
        user: persistedAuthReducer, // Use the persisted reducer for auth
        company: persistedCompanyReducer, // Normal slices without persistence
        subscriptions: subscriptionsSlice,
        HMS: hmsSlice,
        time: persistedTimeReducer,
        customers: customerSlice,
        projects: projectsSlice,
        plannedTimes: plannedTimeSlice,
        users: usersSlice,
        reports: reportsSlice,
        companySettings: companySettingsSlice,
        edits: editSlice,
    },
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore redux-persist actions from the serializable check
                ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
                ignoredPaths: ['time.allTimes.byId', "time.weeks.byId"]
            },
        }).concat(thunk).concat(timestampMiddleware).concat(rehydrateRcTimeMiddleware)//.concat(nameMiddleware),
});

export const persistor = persistStore(store); // Export the persistor to be used in your app
export default store;
