import React, { useState, useRef, useEffect } from 'react';
import { createReport, getClients, getNotSentReports, getReportNumber, getSentReports, setReportNumber } from '../../firebase';
import RapportCard from '../../components/rapport-card';
import ClientCard from '../../components/client';
import { useHistory } from 'react-router';
import { IonContent, IonHeader, IonPage, IonToolbar } from '@ionic/react';
import DefaultWrapper from '../../components/defaultWrapper';
import ButtonListButton from '../../components/miniComponents/buttonListButton';

import { ReactComponent as UserIcon } from '../../assets/icons/material-symbols_person.svg';
import LoadingWhileEmpty from '../../components/miniComponents/loadingWhileEmpty';
import { CheckIfOnPC } from '../../App';
import { Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CollSub } from '../../components/utils/hooks/sub/fetchCollection';
import { addCustomer, createDefaultCustomer, removeCustomer, updateCustomer } from '../../stores/customerSlice';
import { useDispatch, useSelector } from 'react-redux';
import { baseFilters } from '../../components/utils/hooks/filterUtils';


/*export function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
}*/

export function stringToColor(string) {
    let hash = 0;
    let i;

    // Generate hash from string
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    // Convert hash to RGB hex
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }

    // Contrast calculation: convert color to grayscale luminance
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);

    // Calculate brightness using luminance formula
    const brightness = (r * 0.299 + g * 0.587 + b * 0.114);

    // Return black or white for max contrast
    const contrastingColor = brightness > 186 ? '#000000' : '#FFFFFF';

    return { color, contrastingColor };
}


export function stringAvatar(name) {
    if (name === "") return {
        sx: {
            bgcolor: "var(--contrastColor)",
            //icon color
            color: "var(--inputBackground)",
        },
    }

    const { color, contrastingColor } = stringToColor(name);
    return {
        sx: {
            bgcolor: color,
            color: contrastingColor
        },
        children: `${name.split(' ')[0][0]}${name.split(' ').length > 1 ? name.split(' ')[1][0] : ""}`,
    };
}

export default function ClientList(props) {
    const { t } = useTranslation();
    //const [clients, setClients] = useState([]);
    const history = useHistory();
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    //console.log(user)
    const { clients, isLoading } = CollSub({
        selector: (state) => state.customers.allIds,
        path: 'klienter',
        updateFunction: updateCustomer,
        addFunction: addCustomer,
        removeFunction: removeCustomer,
        key: "clients",

        returnItemsByIdSelector: (state) => state.customers.byId,
        nonFBFilters: baseFilters.excludeIfEnded,
        // Only the once created by the user
        filters: [
            // TODO - ADD FILTER TO ONLY ADD THE ONCE THEY ARE IN - If not admin.
            //{ field: "originalCreator.uid", operator: "==", value: user.user.uid },
            //{ field: "endDate", operator: ">", value: new Date().toISOString() },
            //{ field: "endDate", operator: "!=", value: null },
        ]
    });


    function navigateToNewPage(path, information) {
        history.push({
            pathname: path,
            state: information
        });
    }

    return (
        <DefaultWrapper lagring={true} noBackButton={CheckIfOnPC()}>
            <div className='clientList content-ny column'>
                <div className='flexApart'>
                    <h1>{t("Customers", "Kunder")}</h1>
                    <div className='flexClose'>
                        <button className='btn btn-primary bottom-right-header-button-with-header ' onClick={() => {
                            const customer = createDefaultCustomer();
                            dispatch(addCustomer({ document: customer }));
                            navigateToNewPage(`/customer/${customer.id}`, null)
                        }}>
                            {t("New Customer", "Ny Kunde")} +
                        </button>
                    </div>
                </div>
                <div className='gap column'>
                    <LoadingWhileEmpty buttonList={true}>
                        {clients.map((client, index) => {
                            return <ButtonListButton
                                key={client.id}
                                title={client.name}
                                description={client.address}
                                icon={UserIcon}
                                avatar={() => {
                                    return <Avatar {...stringAvatar(client?.name || "")}></Avatar>
                                }}
                                //onPress={(e) => navigateToNewPage('/client', client)}
                                onClick={(e) => navigateToNewPage(`/customer/${client.id}`, null)}
                            />
                        })}
                    </LoadingWhileEmpty>
                </div>
            </div>
        </DefaultWrapper >
    );
}
