import { createDateOutOfFirebaseTimestamp } from "../timeføring";
import { DOUGHNUT_COLORS } from "./doughnut";
import { roundNumberToNearestHalfHour, roundToNearestHalfHour } from "./utils";




const groupByDay = (times) => {
    const groupedByDay = times.reduce((acc, current) => {
        if (!current.endDate) return acc;
        if (current.startDate) {
            const date = createDateOutOfFirebaseTimestamp(current.startDate) // convert Firestore timestamp to JS Date

            const day = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

            if (!acc.has(day)) {
                acc.set(day, []);
            }
            acc.get(day).push(current);
        }

        return acc;
    }, new Map());
    return groupedByDay;
};
/*
for (let [_, recordings] of groupedByDay) {
    recordings.sort((a, b) => {
        if (a.endDate && typeof a.endDate.toDate === 'function' && b.endDate && typeof b.endDate.toDate === 'function') {
            return b.endDate.toDate() - a.endDate.toDate();
        } else {
            return 0;
        }
    });
}*/
/*
const arrayOfPreviousRecordings = Array.from(groupedByDay.entries()).sort().reverse().map(([date, recordings]) => {
    const [year, month, day] = date.split('-');
    const title = `${day}. ${getMonth(parseInt(month) - 1, t).slice(0, 3)} ${year}`; // change the title to the desired format

    return {
        title,
        dates: recordings,
    };
});
*/
const splitIntoTimeType = (times) => {
    const result = {};


    times.forEach(time => {
        const name = time?.timeType?.name || "Normal-";
        if (!result?.[name]) {
            result[name] = [];
        }
        result[name].push(time);
    })
    return result;
};

const splitIntoAddonType = (times) => {
    const result = {};


    times.forEach(time => {
        const addonArray = Array.isArray(time?.tilleg) ? time?.tilleg : [time?.tilleg];

        addonArray.forEach(addon => {
            const name = addon?.name || addon?.navn || "Normal-";
            if (!result[name]) {
                result[name] = [];
            }
            result[name].push(time);
        })
    })
    return result;
}

// Object.values(tilleggsType)
const timeTypesToHours = (array) => array.map(timeTypes => {

    let totalSeconds = 0;
    timeTypes.forEach(time => {
        //console.log(time.startDate, time.endDate)
        if (!time.endDate || !time.startDate) return;
        const startDate = roundToNearestHalfHour(createDateOutOfFirebaseTimestamp(time.startDate));
        const endDate = roundToNearestHalfHour(createDateOutOfFirebaseTimestamp(time.endDate));
        //console.log(startDate, endDate)
        totalSeconds += (endDate - startDate) / 1000; // Sum up total seconds
    });

    let totalHours = totalSeconds / 3600; // Convert total seconds to hours
    return Number(totalHours).toFixed(1); // Return total hours rounded to 2 decimals
});

const splitTimeTypesIntoAddonTypes = (timeTypes) => {
    const result = {};
    timeTypes.forEach(timeType => {
        console.log(timeType)
        const addonType = splitIntoAddonType(timeType);
        console.log(addonType)
        Object.keys(addonType).forEach(key => {
            if (!result[key]) {
                result[key] = [];
            }
            result[key].push(addonType[key]);
        });
    });
    return result;
}

export const getDoughnutData = (times) => {
    const timeTypes = splitIntoTimeType(times);
    // TODO: Implement the logic to split the timeTypes into addonTypes - for second inner-doughnut.
    // SHOULD split into timetypes, take the Addon time - timetype time to get a "non-used" time.
    //const addonTypes = splitTimeTypesIntoAddonTypes(Object.values(timeTypes));

    const data = timeTypesToHours(Object.values(timeTypes));
    //labels are the keys of the tilleggsTypeArray
    let labels = Object.keys(timeTypes).map((label, index) => `${String(data[index])?.replace('.', ',')} t - ${label}`)
    //console.log(addonTypes)
    
    //let labels = tilleggsTypeArray.map((label, index) => `${String(data[index])?.replace('.', ',')} t - ${label}`)

    return {
        labels: labels,
        datasets: [
            {
                label: 'Timer',
                data: data,
                backgroundColor: DOUGHNUT_COLORS,
                hoverBackgroundColor: ['#FFFFFF'],
                labelColor: '#FFFFFF',
                //borderColor: '#faebd700',
                borderWidth: 0, // Increase the borderWidth to create spacing
                color: '#FFFFFF',
                borderRadius: 0,
                //spacing: 2,

            }
        ]
    };
};


export const calculateHoursOfTimes = (times) => {
    let hours = 0;
    times.forEach(time => {
        if (time.endDate && time.startDate) {
            const startDate = createDateOutOfFirebaseTimestamp(time.startDate);
            const endDate = createDateOutOfFirebaseTimestamp(time.endDate);

            const timeDiff = endDate - startDate;
            const hoursDiff = timeDiff / 1000 / 60 / 60;
            hours += hoursDiff;
        }
    });

    return roundNumberToNearestHalfHour(Number(hours).toFixed(1));
};

export function isInvalidDate(date) {
    if (!date?.getTime) return isNaN(new Date(date).getTime());
    return isNaN(date.getTime());
}