// store/tasksSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createDateOutOfFirebaseTimestamp } from '../pages/timeføring/timeføring';
import { createId } from '../firebase';
import { setHMSReport, uploadHMSReport } from '../database/hms';
import store from './mainStore';

const Avvik = {
    sted: {},
    id: createId(),
    dato: new Date().toISOString(),
    created: new Date().toISOString(),
    hendelse: "",
    resultat: "",
    description: "",
    årsak: "",
    tiltak: "",
    ansvarlig: "",
    frist: "",
    oppfølging: "",
    status: "",
    kommentar: "",
    vedlegg: "",
    oppdaget: new Date().toISOString(),
    begrunnelser: [],
    emails: [
        { email: "", id: createId() }
    ],
    årsak: "",
    signatur: null,
    type: "avvik",
    logo: null
}

const SJA = {
    id: createId(),
    sted: {},
    dato: new Date().toISOString(),
    created: new Date().toISOString(),
    aktivitet: "",
    hendelse: "",
    resultat: "",
    tiltak: "",
    ansvarlig: "",
    frist: new Date().toISOString(),
    oppfølging: "",
    status: "",
    kommentar: "",
    vedlegg: "",
    signatur: null,
    deltagere: [],
    utstyr: [],
    emails: [
        { email: "", id: createId() }
    ],
    lines: [
        { beskrivelse: "Ny Oppgave", farer: "", tiltak: "", kontroll: "", ansvarlige: [], id: createId() }
    ],
    årsaker: [],
    logo: null,
    type: "sja",
    logo: null
}

const RUH = {
    sted: {},
    dato: new Date().toISOString(),
    created: new Date().toISOString(),
    hendelse: "",
    resultat: "",
    årsak: "",
    tiltak: "",
    ansvarlig: "",
    frist: new Date().toISOString(),
    oppfølging: "",
    status: "",
    emails: [
        { email: "", id: createId() }
    ],
    kommentar: "",
    vedlegg: "",
    signatur: null,
    id: createId(),
    type: "ruh",
    logo: null
}

export const uploadHMS = createAsyncThunk('hms/upload', async (report, { dispatch }) => {
    dispatch(updateHMSReport({ type: report.type, document: {...report, sending:true} }))
    try {
    await uploadHMSReport(report)
    } catch (error) {
        dispatch(updateHMSReport({ type: report.type, document: {...report, sending:false, error:"Error Sending"} }))
    }
    dispatch(removeHMSReport({ type: report.type, id: report.id }))
});

const HMSSlice = createSlice({
    name: 'hms',
    initialState: {
        // Maybe add sent and not sent?
        avvik: {
            reports: [],
            fetched: false,
        },
        ruh: {
            reports: [],
            fetched: false,
        },
        sja: {
            reports: [],
            fetched: false,
        },
    },
    reducers: {
        setHMSByType: {
            reducer: (state, action) => {
                console.log(action.payload)
                const { type, documents } = action.payload;
                state[type].reports = documents;
                if (!state[type].fetched) state[type].fetched = true;
            },
            prepare: ({ type, documents }) => {
                console.log(document)
                return {
                    payload: {
                        type, documents: documents.map(d => {
                            return {
                                ...d,
                            }
                        })
                    }
                };
            },
        },
        addHMSReport: {
            reducer: (state, action) => {
                const { type, document } = action.payload;
                if (state[type].reports.find(r => r.id === document.id)) return;
                state[type].reports.push(document);
                if (!state[type].fetched) state[type].fetched = true;
            },
            prepare: ({ type, document }) => {
                console.log(document)
                return {
                    payload: {
                        type, document: {
                            ...document,
                        }
                    }
                };
            },
        },

        updateHMSReport: {
            reducer: (state, action) => {
                const { type, document } = action.payload;
                console.log(document)
                console.log(state[type].reports)
                state[type].reports = state[type].reports.map((r) => (r.id === document.id ? document : r));
                if (!state[type].fetched) state[type].fetched = true;
            },
            prepare: ({ type, document }) => {
                console.log(document)
                return {
                    payload: {
                        type, document: {
                            ...document,
                        }
                    }
                };
            },
        },

        removeHMSReport: {
            reducer: (state, action) => {
                const { type, id } = action.payload;
                console.log(type, id)

                state[type].reports = state[type].reports.filter((r) => r.id !== id);
            },
            prepare: ({ type, id }) => {
                return {
                    payload: {
                        type, id
                    }
                };
            },
        },


        createNewHMSReport: {
            reducer: (state, action) => {
                console.log(action.payload)
                const { type, document } = action.payload;
                state[type].reports.push(document);
            },
            prepare: ({ type, id }) => {
                let document = {};
                console.log(type, id)
                if (type === "avvik") document = Avvik;
                if (type === "sja") document = SJA;
                if (type === "ruh") document = RUH;
                document.originalCreator = store.getState().user.user;
                return {
                    payload: {
                        type,
                        document: {
                            ...document,
                            id: id,
                        }
                    }
                };
            },
        },

        resetHMS: (state) => {
            state.avvik = {
                reports: [],
                fetched: false,
            }
            state.ruh = {
                reports: [],
                fetched: false,
            }
            state.sja = {
                reports: [],
                fetched: false,
            }
        }
    },
});

export const { setHMSByType, updateHMSReport, createNewHMSReport, addHMSReport, removeHMSReport, resetHMS } = HMSSlice.actions;

export default HMSSlice.reducer;
