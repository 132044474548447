import React, { useState, useEffect } from 'react';
import { isInvalidDate } from '../../pages/timeføring/utils/timeCalculations';


const Timer = ({ startDate, stopDate, onChange, countUp, showSeconds, showSecondsIfNoHour, ...rest }) => {
    const calculateTimePassed = () => {
        const start = startDate ? new Date(startDate) : new Date();
        const end = stopDate ? new Date(stopDate) : new Date();
        if (start > end) {
            return 0;
        }
        if (isInvalidDate(start) || isInvalidDate(end)) {
            return 0;
        }
        // console.log(start, end)
        const timePassed = end - start;
        return Math.floor(timePassed / 1000); // timePassed is in milliseconds, divide by 1000 for seconds
    };

    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600) || 0;
        const minutes = Math.floor((seconds % 3600) / 60) || 0;
        seconds = seconds % 60 || 0;

        // Return hours, minutes and seconds
        return {
            hours,
            minutes,
            seconds
        };
    };

    const [totalSeconds, setTotalSeconds] = useState(calculateTimePassed());

    useEffect(() => {
        setTotalSeconds(calculateTimePassed());
    }, [startDate, stopDate]);

    useEffect(() => {
        if (countUp) {
            const intervalId = setInterval(() => {
                setTotalSeconds(totalSeconds => totalSeconds + 1);
            }, 1000);

            // Clean up function
            return () => clearInterval(intervalId);
        }

    }, [countUp]);

    const time = formatTime(totalSeconds);

    useEffect(() => {
        if (typeof onChange === 'function') {
            onChange(time);
        }
    }, [time, onChange]);

    const roundToNearest15Minutes = (minutes) => {
        if (minutes < 15) {
            return 0;
        } else if (minutes < 30) {
            return 15;
        } else if (minutes < 45) {
            return 30;
        } else {
            return 45;
        }
    }

    const paddedSeconds = String(time.seconds).padStart(2, '0');
    const paddedMinutes = String(time.minutes).padStart(2, '0');
    const paddedHours = String(time.hours).padStart(2, '0');
    const roundedPaddedMinutes = roundToNearest15Minutes(time.minutes).toString().padStart(2, '0');


    return (
        <div {...rest} className={'row noGap ' + rest?.className}>
            <p className={rest?.className}>{!showSecondsIfNoHour || (showSecondsIfNoHour && time.hours) ? paddedHours + ":" : null}</p>
            <p className={rest?.className}>{paddedMinutes}</p>
            {(showSeconds || (showSecondsIfNoHour && !time.hours)) && <p className={rest?.className}>:{paddedSeconds}</p>}
        </div>
    );
};

export default Timer;
