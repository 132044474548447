import { createSlice } from "@reduxjs/toolkit";

const usersSlice = createSlice({
    name: 'users',
    initialState: {
        byId: {},
        allIds: [],
    },
    reducers: {
        addUsers: {
            reducer:
                (state, action) => {
                    const { document } = action.payload;
                    //state.byId[path] = subscription;
                    if (state.allIds.includes(document.id)) return;
                    state.allIds.push(document.id);
                    state.byId[document.id] = document;
                },
            prepare: ({ document }) => {

                return {
                    payload: { document: document }
                };
            },
        },
        updateUsers: {
            reducer: (state, action) => {
                const { document } = action.payload;
                state.byId[document.id] = document;
            },
            prepare: ({ document }) => {

                return {
                    payload: { document: document }
                };
            },
        },

        removeUsers: (state, action) => {
            //console.log(action.payload);
            const id = action.payload;
            state.allIds = state.allIds.filter(id => id !== id);
            delete state.byId[id];
        }
    },
});


export const { addUsers, updateUsers, removeUsers } = usersSlice.actions
export default usersSlice.reducer