




export default function CircleIconButton(props) {
    const { className, ...rest } = props;
    return (
        <button className={"button-round " + className} {...rest}>
            <props.icon></props.icon>
        </button>
    );
}