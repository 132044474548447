import CircleIcon from "../miniComponents/circleIcon";
import CustomerModal from "./CustomerModal";
import { useEffect, useState } from "react";
import TimetypeModal from "./TimetypeModal";
import { ClockIcon } from "@mui/x-date-pickers";
import { ArrowDropDown } from "@mui/icons-material";
import { CollSub } from "../utils/hooks/sub/fetchCollection";
import { setSetting } from "../../stores/companySettingsSlice";


export default function TimetypeModalButton({ value, label, icon, title, items, onChange }) {
    const [modalVisible, setModalVisible] = useState(false);
    const { settings, isLoading } = CollSub({
        selector: (state) => state.companySettings.byId.timeSettings,
        path: 'settings',
        setFunction: setSetting,
        key: "settings",
        payload: { key: "timeSettings" },
        //returnItemsByIdSelector: (state) => state.customers.byId,
        // Only the once created by the user
        filters: [
        ]
    });

    
    useEffect(() => {
        console.log(settings?.timeTypes)
        if (value) return
        if (!settings?.timeTypes) return
        if (settings.timeTypes.length === 0) return
        console.log(settings.timeTypes.find((timeType) => timeType.default === true))
        onChange(settings.timeTypes.find((timeType) => timeType.default === true))
    }, [settings, value])


    return (
        <div className="button-list stretch-width" >
            <button type={"button"} className="button-list-button stretch-width" onClick={() => {
                setModalVisible(true)
            }}>
                <div className="row relative center-column stretch-width flexApart">
                    <label className="button-list-button-label">{label}</label>
                    <div className="row center-column">
                        <CircleIcon icon={ClockIcon} />
                        <p>{value?.name}</p>
                    </div>
                    <ArrowDropDown />


                </div>

            </button>
            <TimetypeModal
                visible={modalVisible}
                value={value}
                onChange={(e) => {
                    if (onChange) onChange(e);
                    setModalVisible(false)
                }} onClose={() => setModalVisible(false)} />
        </div>
    )
}